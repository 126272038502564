import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { VerticalAlignmentBasic, VerticalAlignmentBasicMore } from "../../examples/utilities/VerticalAlignment";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CodeBlock code={VerticalAlignmentBasic} mdxType="CodeBlock">
  <p>
    Change the alignment of elements with the <code>vertical-alignment</code>{" "}
    utilities. Please note that vertical-align only affects inline,
    inline-block, inline-table, and table cell elements.
  </p>
  <p>
    Choose from <code>.align-baseline</code>, <code>.align-top</code>,{" "}
    <code>.align-middle</code>,<code>.align-bottom</code>,{" "}
    <code>.align-text-bottom</code>, and <code>.align-text-top</code> as needed.
  </p>
  <p>With inline elements:</p>
    </CodeBlock>
    <CodeBlock code={VerticalAlignmentBasicMore} className="bd-example notranslate" mdxType="CodeBlock">
  <p>With inline elements:</p>
    </CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      